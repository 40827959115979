.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

form {
    width: 100%;
}

.iman4-button-with-icon > span {
    margin-left: 4px;
    text-transform: uppercase;
}

table.iman4-blueprint-details {
    width: 100%;
}

table.iman4-blueprint-details td {
    padding: 24px;
    width: 25%;
    border-left: solid rgba(0, 0, 0, 0.08) thin;
    border-right: solid rgba(0, 0, 0, 0.08) thin;
    text-align: start;
    vertical-align: top;
}

table.iman4-blueprint-details td:nth-of-type(1) {
    border-left: none;
}

table.iman4-blueprint-details td:last-of-type {
    border-right: none;
}

table.iman4-blueprint-details tr {
    border-top: solid rgba(0, 0, 0, 0.08) thin;
    border-bottom: solid rgba(0, 0, 0, 0.08) thin;
}

table.iman4-blueprint-details tr:nth-of-type(1) {
    border-top: none;
}

table.iman4-blueprint-details tr:last-of-type {
    border-bottom: none;
}

table.iman4-blueprint-details {
    border-collapse: collapse;
}

table.iman4-blueprint-details td.iman4-col-50 {
    width: 50%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.highlight {
    animation: blinkingText 1.66s infinite ease-in-out;
}

@keyframes blinkingText {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.66;
    }
    100% {
        opacity: 1;
    }
}
